<template>
  
  <div class="main-container">

    <div class="top-bar">
      
      <div class="icon-links">

        <div @click="openLink('https://discord.com/invite/deathwishnft')" class="link">
          <img src="./assets/discord.png"/>
        </div>

        <div @click="openLink('https://etherscan.io/address/0x67e3e965ce5ae4d6a49ac643205897acb32fcf6e')" class="link">
          <img src="./assets/etherscan.png"/>
        </div>

        <div @click="openLink('https://twitter.com/DeathWishNFT')" class="link">
          <img src="./assets/twitter.png"/>
        </div>

        <div @click="openLink('https://opensea.io/collection/deathwish-365')" class="link">
          <img src="./assets/opensea.png"/>
        </div>

        

      </div>

    </div>



    <div class="description-container">

      <div class="top-description">

        <div class="description-info">
          <div class="description-header">
            <img class="description-img" src="./assets/blacklogo_clean.png"/>
          </div>
          <div class="description-body">

            <p :style="{'font-size':'23px', 'text-align':'center', 'font-weight':'bold', 'margin-bottom':'40px'}" class="MsoNormal"><span lang="EN-CA">Welcome to the DEATHWISH 365
Contract Migration!<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN-CA">Back at the dawn of 2022, when the
  <a href="https://deathwishnft.io/en-ca/pages/about">DEATHWISH 365</a> collection first began, we had no idea how big the project would
grow or what opportunities would lay ahead. So, despite better alternatives,
the project began its genesis as an OpenSea token that had limited
functionality.<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN-CA">Today we’re excited to advance <a href="https://deathwishnft.io/en-ca/pages/about">DEATHWISH 365</a> by migrating all DW365 tokens to a new contract that will enable several advancements leading to the launch of <a href="https://deathwishnft.io/en-ca/pages/twenty-twenty-three">DEATHWISH TRADING CARDS</a> later this year.<o:p></o:p></span></p>

<p class="MsoNormal"><span lang="EN-CA">Holders, please follow the instructions
below carefully, and help us achieve a seamless transition as we move ahead
with the next stage of <a href="http://discord.gg/deathwishnft">DEATHWISH NFT</a>.<o:p></o:p></span></p>

<div class="mint-container">

<div v-if="loading" class="wallet-not-connected">
  <label :style="{'margin':'10px'}">{{ loadingMessage }}</label>

</div>

<div v-if="!walletConnected && !loading" class="wallet-not-connected">
  <label :style="{'margin-bottom':'10px'}">Please connect your wallet</label>

  <div @click="init" class="death-button">
    Connect Wallet
  </div>

</div>

<div v-if="walletConnected && correctChain && !isContractApproved && !loading" class="wallet-not-connected">
  <label :style="{'margin-bottom':'10px'}">Please approve the legacy Deathwish 365 contract</label>

  <div @click="setApproval" class="death-button">
    Approve
  </div>

</div>


<div v-if="walletConnected && !correctChain && !loading">
  <label>Please switch to the Ethereum Mainnet network</label>
</div>

<div class="mint-section" v-if="walletConnected && correctChain && isContractApproved && !loading">

  <label class="wallet-label">Connected to {{ getWallet() }}</label>

  <div v-if="!confirmScreen" class="mint-options">

    <label :style="{'width':'70%', 'margin':'5px','text-align':'left', 'margin-bottom':'15px'}">Input the token IDs of all your DW365 NFTs below. Separate them by commas.</label>
    <label :style="{'width':'70%', 'margin':'5px','text-align':'left', 'margin-bottom':'15px'}">For example: <br>If you own DW365-081 and DW365-220</label>
    <label :style="{'width':'70%', 'margin':'5px','text-align':'left', 'margin-bottom':'15px'}">Just write:<br> 81,220</label>

    <input id="tokenId" placeholder="Token ids like 1,2,3,4" :style="{'margin-bottom':'5px'}" />

    <div @click="goToContinue" class="death-button">
      Submit
    </div>

  </div>


  <div v-if="confirmScreen" class="mint-options">

    <label :style="{'width':'90%', 'margin':'5px', 'text-align':'center'}">You are about to migrate the following tokens:</label>

    <div class="nft-container">
      <div v-for="tokenId in tokenIds" :key="tokenId" class="nft">
        <img :src="getImage(tokenId)"/>
        <label>{{ getName(tokenId) }}</label>
      </div>
    </div>

    <label :style="{'width':'90%', 'margin':'5px', 'text-align':'center', 'margin-bottom':'15px'}">Please make sure you own all of these tokens in the connected wallet to process successfully.</label>

    <div @click="mint" class="death-button">
      Migrate
    </div>

  </div>

</div>



</div>

<p :style="{'margin-top':'45px'}" class="MsoNormal"><b><span lang="EN-CA" style="font-size:16.0pt;line-height:
107%">How To Migrate:<o:p></o:p></span></b></p>

<p class="MsoListParagraphCxSpFirst" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">Connect your wallet<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpLast" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">In the field below, input the
ID of the skulls that you own.<o:p></o:p></span></p>

<div style="mso-element:para-border-div;border:solid windowtext 1.0pt;
mso-border-alt:solid windowtext .5pt;padding:1.0pt 0cm 1.0pt 4.0pt;margin-left:
18.0pt;margin-right:0cm;text-align: center;">

<p class="MsoNormal" style="border:none;mso-border-alt:solid windowtext .5pt;
padding:0cm;mso-padding-alt:1.0pt 0cm 1.0pt 4.0pt"><b><span lang="EN-CA">Format
the ID field with only numbers and commas!<o:p></o:p></span></b></p>

<p class="MsoNormal" style="border:none;mso-border-alt:solid windowtext .5pt;
padding:0cm;mso-padding-alt:1.0pt 0cm 1.0pt 4.0pt"><span lang="EN-CA">For example:<br>
If you own DW365-081 and DW365-220<br>
<br>
Just write:<br> <b>81, 220<o:p></o:p></b></span></p>

</div>




<p class="MsoListParagraphCxSpFirst" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1;margin-top:40px;"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">Press <b>SUBMIT</b> to confirm
your entry.<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">Double check that all your
skulls are present in the display.<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">Press <b>MIGRATE</b> to
initiate the migration.<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">6.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">Approve the transfer allowance
in your wallet (there will be a gas fee).<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">7.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">Approve the migration
transaction in your wallet (there will be another gas fee).<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">8.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">Await processing, as your old
DW365 is transferred out, and a new one arrives.<o:p></o:p></span></p>

<p class="MsoListParagraphCxSpLast" style="text-indent:-18.0pt;mso-list:l0 level1 lfo1"><!--[if !supportLists]--><span lang="EN-CA" style="mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin"><span style="mso-list:Ignore">9.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><!--[endif]--><span lang="EN-CA">You'll receive a gas refund up to 0.002 ETH per skull within 14 days<o:p></o:p></span></p>


<label :style="{'font-style':'italic', 'font-size':'12px'}">(Gas refunds will only be available on transactions processed before July 1st 2023)</label>

          </div>
        </div>
      </div>

    </div>


  </div>

</template>

<script>
import Web3 from 'web3';
import detectEthereumProvider from "@metamask/detect-provider";

import ERC1155 from '../data/ERC1155.js';
import DEATHWISH_ABI from '../data/Deathwish.js';

import {STOREFRONT_ADDRESS, DEATHWISH_ADDRESS} from '../data/contracts.js';

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      walletConnected: false,
      wallet: "",
      correctChain: false,
      isContractApproved: true,
      loading: false,
      loadingMessage: "",
      confirmScreen: false,
      tokenIds: [],
    }
  },
  methods: {
    getName(id) {

      let tokenName = 'DW365-' + (id < 10 ? '00' + id : id < 100 ? '0' + id : id);

      return tokenName;
    },
    getImage(id) {
      let format = '.jpg';

      id = parseInt(id);

      let tokenName = 'DW365-' + (id < 10 ? '00' + id : id < 100 ? '0' + id : id);

      let location = require("./assets/300px/" + tokenName + format);

      return location;
    },
    goToContinue() {

      let id = document.getElementById('tokenId').value;

      id = id.replace(/ /g, ',');

      id = id.replaceAll(' ','');

      let ids = id.split(',');

      let uniqueIds = [];

      // Check if the input is an array of integers
      if (ids.every((element) => !isNaN(parseInt(element)) && parseInt(element).toString() === element)) {
        ids.forEach((id) => {
          if (!uniqueIds.includes(id)) {
            uniqueIds.push(id);
          }
        });

        uniqueIds = uniqueIds.map((id) => {
          return parseInt(id);
        });
        
        this.confirmScreen = true;
        this.tokenIds = uniqueIds;
      } else {
        this.loading = true;
        this.loadingMessage = "Invalid input. Please use a comma separated list of token ids.";

        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    getWallet() {
      return this.wallet.substring(0,5) + "...." + this.wallet.substring(this.wallet.length - 5, this.wallet.length);
    },
    async isApproved() {
      let web3 = new Web3(this.provider);
      let instance = new web3.eth.Contract(ERC1155, STOREFRONT_ADDRESS);

      let t = this;

      await instance.methods
        .isApprovedForAll(this.wallet, DEATHWISH_ADDRESS)
        .call()
        .then((res) => {
          console.log("Is approved:", res);

          t.isContractApproved = res;
        });
    },
    async mint() {
      let web3 = new Web3(this.provider);
      let instance = new web3.eth.Contract(DEATHWISH_ABI.abi, DEATHWISH_ADDRESS);

      let t = this;

      let ids = this.tokenIds;

      this.loading = true;
      this.loadingMessage = "Waiting for mint transaction to be signed..";

      await instance.methods
      .claimToken(ids)
      .send({
        from: this.selectedAccount,
      })
      .on("transactionHash", function() {
        t.loadingMessage = "Waiting for transaction to be confirmed..";
      })
      .on("receipt", function() {
        t.loadingMessage = "Success!";

        t.isApproved();

        setTimeout(() => {
          t.loading = false;
          t.confirmScreen = false;
        }, 2000);
      })
      .on("error", function() {
        t.loadingMessage = "Transaction failed, try again..";

        setTimeout(() => {
          t.loading = false;
          t.confirmScreen = false;
        }, 2000);
      });
    },
    async setApproval() {
      let web3 = new Web3(this.provider);
      let instance = new web3.eth.Contract(ERC1155, STOREFRONT_ADDRESS);

      let t = this;

      this.loading = true;
      this.loadingMessage = "Waiting for approval transaction to be signed..";

      await instance.methods
      .setApprovalForAll(DEATHWISH_ADDRESS, true)
      .send({
        from: this.selectedAccount,
      })
      .on("transactionHash", function() {
        t.loadingMessage = "Waiting for transaction to be confirmed..";
      })
      .on("receipt", function() {
        t.loadingMessage = "Success!";

        t.isApproved();

        setTimeout(() => {
          t.loading = false;
          t.confirmScreen = false;
        }, 2000);
      })
      .on("error", function() {
        t.loadingMessage = "Transaction failed, try again..";

        setTimeout(() => {
          t.loading = false;
          t.confirmScreen = false;
        }, 2000);
      });
    },
    openLink(url) {
      window.open(url, '_blank').focus();
    },
    async connectWallet() {
      this.provider = await detectEthereumProvider();
      if (this.provider === window.ethereum) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            this.selectedAccount = Web3.utils.toChecksumAddress(accounts[0]);
            this.wallet = this.selectedAccount;
            this.walletConnected = true;
            this.afterConnect();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    async afterConnect() {
      
      this.checkChain();

      this.isApproved();
    },
    async init() {
      window.ethereum.on('chainChanged', () => this.checkChain());
      window.ethereum.on('accountsChanged', () => this.connectWallet());
      window.ethereum.on('connected', () => this.connectWallet());

      this.connectWallet();
      
      setTimeout(() => {
        this.attemptedConnect = true;
      }, 1000);

    },

    async checkChain() {
      var netId = window.ethereum.chainId
      if(netId === '0x1')
        this.correctChain = true;
      else
        this.correctChain = false;


    },
  },
}

//Fonts are Avenir and Viga
</script>


<style>

@font-face {
  font-family: "Avenir";
  src: url("./assets/AvenirNextCyr-Medium.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Viga&display=swap');




#app {
  font-family: Viga;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #da3332;
}

body {
  margin: 0px;
  background-color: #1f1f21;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.icon-links {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.link {
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  user-select: none;
  cursor: pointer;
}

.nft {
  position: relative;
  display: flex;
  max-width: 125px;
  max-height: 125px;
  margin: 20px;
  justify-content: center;
  flex-direction: column;
}

.nft .img {
  width: 100%;
  height: 100%;
}

.nft-container {
  position: relative;
  display: flex;
  height: fit-content;
  margin-bottom: 50px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.main-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mint-section {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.mint-options {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

}

.wallet-label {
  margin: 15px;
  color: #da3332;
}

.top-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #da3332;
  color: #1f1f21;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

.top-bar img {
  height: 75%;
}

.image-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 50px;
}

.image-container img {
  width: 70%;
}

.description-container {
  width: 80%;
  max-width: 800px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f2f33;
  padding: 20px;
  margin: 50px;
  flex-direction: column;
}



.description-info {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.description-body {
  font-family: Avenir;
  color: #c9c9c9;
  width: 75%;
  text-align: left;
}

.description-header {
  position: relative;
  display: flex;
  font-size: 25px;
  font-family: Viga;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.description-img {
  position: relative;
  display: flex;
  height: 150px;
  width: 90%;
  margin-bottom: 10px;
}

.top-description {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mint-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #1f1f21;
}

.wallet-not-connected {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.death-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #da3332;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #1f1f21;
  font-family: Avenir;
  letter-spacing: 0.1px;
  user-select: none;
  cursor: pointer;
}

@media (max-width: 576px) {
}

</style>
